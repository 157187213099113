"use client";

import { useSession } from "next-auth/react";

export function ClientAuthChecker() {
  const session = useSession();

  if (!session) {
    return null;
  }
}
