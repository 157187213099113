import { UserTermsOfService } from "@yardzen/components/user-terms-of-service";
import { identifyLogRocketUser } from "@yardzen/next-client-util";
import { Session } from "next-auth";
import { useSession } from "next-auth/react";
import { ReactNode } from "react";

export default function MainContent({
  mustAcceptTerms,
  userId,
  children,
}: {
  mustAcceptTerms: boolean;
  userId: string | undefined;
  children: ReactNode;
}) {
  const { data: session } = useSession() as {
    data: Session & { user: { id?: string; email?: string } };
    status: "authenticated" | "loading" | "unauthenticated";
  };
  if (session?.user?.id && session?.user?.email) {
    identifyLogRocketUser(session.user.id, session.user.email);
  }

  if (mustAcceptTerms && userId) {
    return <UserTermsOfService userId={userId} />;
  }

  return children;
}
